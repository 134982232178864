








import { MetaInfo } from 'vue-meta'
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'

import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, GroupType } from '@/store/types'

@Component({
  components: {
    LinkTabs,
  },
})
export default class CoursesItemGroup extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  private get tabs () {
    return this.group ? [
      {
        name: 'Основа',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}`,
      },
      {
        name: 'Группы',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}/description`,
      },
      {
        name: 'Пакеты',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}/prices`,
      },
      {
        name: 'Программа',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}/program`,
      },
      {
        name: 'Отзывы',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}/reviews`,
      },
      {
        name: 'Рекомендации',
        to: `/manager/control/courses/${this.course.id}/${this.group.type.value}/recommendations`,
      },
    ] : []
  }

  private get group () {
    return ManagerCoursesModule.group
  }

  private created () {
    this.fetchGroup()
  }

  private fetchGroup () {
    ManagerCoursesModule.fetchGroup({
      courseID: parseInt(this.$route.params.courseID, 10),
      type: this.$route.params.groupType as GroupType,
    })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: `${this.group ? this.group.type.name : 'Группа'} - ${this.course.title}`,
    }
  }

  @Watch('$route.params.groupType')
  private watchGroupType() {
    this.fetchGroup()
  }
}
